@import '~bootstrap/scss/accordion';

.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, 0.85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

.btn-toggle[aria-expanded='true'] {
  color: rgba(0, 0, 0, 0.85);
}
.btn-toggle[aria-expanded='true']::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.accordion-body {
  padding-left: 0;
  padding-right: 0;
}
.accordion-item {
  border: none;
  background: transparent;
  border-bottom: none;
  border-bottom: 1px solid $gray-200;
  color: $body-color;
}

.accordion-button {
  font-family: $font-family-sans-serif;
  font-size: $font-size-lg;
  color: $navy;
  background: transparent;
  &:hover {
    color: var(--#{$prefix}accordion-active-color);
  }
  &:not(.collapsed) {
    background: transparent;
    // border-bottom: none;
    // border-bottom: 1px solid $gray-200;
  }
  &:focus {
    box-shadow: none;
  }
}

.accordion-collapse.content {
  max-height: 0;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.list-style-alpha {
  list-style-type: lower-alpha;
}

.accordion-collapse.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
