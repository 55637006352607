//
// main
//
body {
  color: $body-color;
  font-weight: $font-weight-light;
}

a {
  text-decoration: none;
  font-weight: $font-weight-medium;

  &:hover {
    transition: 0.3s ease-in-out;
  }
}

img {
  &.circular {
    border-radius: 100%;
  }
}

//
// z-index
//
.z-high {
  z-index: 3;
}
.z-low {
  z-index: 2;
}

.z-base {
  z-index: 1;
}

//
// Shadows
//
.shadow-xl {
  box-shadow: $box-shadow-xl;
}

.shadow-soft {
  box-shadow: 0 1.225rem 0.75rem -0.75rem rgba(80, 102, 134, 0.1);
}

//
// Cursor
//
.no-highlight {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.hover-pointer:hover {
  cursor: pointer;
}

.display-linebreak {
  white-space: pre-line;
}

//
// Backgrounds
//
.bg-gradient-faintBlue {
  background-image: linear-gradient(to bottom right, $faintBlue, white);
}

.bg-img-wisps-dark {
  background-image: url('~ds_img/WispOverlayDarkR.png'),
    linear-gradient(to right bottom, #506686, rgba(80, 102, 134, 0.9));
  background-position: 100% bottom;
  background-repeat: no-repeat;
}

.bg-img-wisps {
  background-image: url('~ds_img/WispOverlayDarkR.png'),
    linear-gradient(to bottom, $faintBlue, $white);
  background-position: 100%;
  background-repeat: no-repeat;
}

// Lists
.list-terms li {
  padding-bottom: 0.325rem;

  @include font-size(0.95rem);
  line-height: $line-height-md;

  @include media-breakpoint-up(sm) {
    @include font-size(1.1rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(1.15rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(1.2rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(1.25rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(1.3rem);
  }
}

.list-alpha {
  list-style-type: lower-alpha;
  margin-bottom: 2rem;
}

.list-roman {
  list-style-type: lower-roman;
  margin-bottom: 2rem;
}

.list-alpha li,
.list-roman li {
  margin: 10px 0;
}

// Custom Scrollbar
::-webkit-scrollbar {
  width: 10px;
  &-track {
    background: $gray-200;
    border-radius: 10px;
  }
  &-thumb {
    background: $gray-400;
    border-radius: 10px;
    &:hover {
      background: $gray-500;
    }
  }
}

//
// Unsets elements from parent when fixed to bottom
//
@include media-breakpoint-up(lg) {
  .fixed-bottom-lg-unset {
    bottom: unset !important;
    position: unset !important;
  }
}

// -----------------------------------------------------------------------------
// Demo only styles
// -----------------------------------------------------------------------------
.Demo_Header {
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 1.5;
}

.Demo_Container {
  background: $white;
  box-shadow: $box-shadow-sm;
  padding: $spacer * 3;
}

.Demo_Section {
  scroll-margin-top: 75px;
}

// .block__element--modifier
.viewer_sidebar {
  .property_card {
    border-bottom: 1px solid $gray-300;
    padding-bottom: $spacer;
    margin-bottom: $spacer;

    &:last-child {
      border-bottom-width: 0;
      margin-bottom: 0;
    }
  }
}
