@import '~bootstrap/scss/navbar';

.navbar {
  padding: 1rem;
}

.navbar-nav {
  .dropdown-menu {
    position: static;
  }
}

.navbar-toggler {
  color: var(--#{$prefix}navbar-color);
  border: none;
  outline: none;

  &:hover {
    text-decoration: none;
    outline: none;
  }

  &:focus {
    text-decoration: none;
    outline: none;
    border: none;
    box-shadow: none;
  }
}
