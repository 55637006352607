@import '~bootstrap/scss/nav';

.nav-pills .nav-link {
  padding: $nav-link-sm-padding-y $nav-link-sm-padding-x;
  font-size: $nav-link-sm-font-size;
}

// For Standard Navbar Behavior
.navbar {
  .navbar-nav {
    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 4px solid transparent;
      transition: 75ms ease-in-out;

      @include font-size(0.7rem);
      line-height: $line-height-sm;
      @include media-breakpoint-up(sm) {
        @include font-size(0.75rem);
      }
      @include media-breakpoint-up(md) {
        @include font-size(0.8rem);
      }
      @include media-breakpoint-up(lg) {
        @include font-size(0.85rem);
      }
      @include media-breakpoint-up(xl) {
        @include font-size(0.9rem);
      }
      @include media-breakpoint-up(xxl) {
        @include font-size(1rem);
      }

      &-footer {
        margin-bottom: 1em;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
        &:hover {
          border-bottom: 4px solid transparent !important;
          color: $gray-500;
          transition: 75ms ease-in-out;
        }
      }

      &-icon {
        color: $gray-600;
        &:hover {
          color: $forest;
        }
        font-size: 15px;
        @include media-breakpoint-up(lg) {
          font-size: 20px;
        }
      }

      &:hover {
        border-bottom: 4px solid $forest;
        transition: 75ms ease-in-out;
      }

      &.active {
        border-bottom: 4px solid $forest;
      }
    }
  }
}
