//
// Sagan Stylesheet
// Copyright SHARE Technologies Inc. 2022
//
// This stylesheet uses Bootstrap v5.2.3 (htts://getbootstrap.com)
// Copyright 2011-2018 The Bootstrap Authors
// Copyright 2011-2018 Twitter, Inc.
// Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
//

@import '~design-system/scss/index.scss';
@import '~react-toastify/dist/ReactToastify.css';

.bottom-nav {
  box-shadow: 0 -1rem 1rem rgba($black, 0.175);
}

// Compact Card
.property__badge-image {
  height: 150px;
  width: 100%;

  img {
    object-fit: cover;
    height: 150px;
    width: 100%;
    display: block;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @include media-breakpoint-up(md) {
    height: 100%;
    width: 100px;

    img {
      height: 100px;
      width: 100px;
      border-top-left-radius: $border-radius;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: 0;
    }
  }
}

// Hero Image
.hero-image {
  height: 200px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: 250px;
  }
  @include media-breakpoint-up(md) {
    height: 350px;
  }

  @include media-breakpoint-up(lg) {
    height: 450px;
  }
  @include media-breakpoint-up(xl) {
    height: 500px;
  }
}

// Charts
.chart-basic {
  max-height: 200px;
  position: relative;
  width: 100%;
  height: auto;

  @include media-breakpoint-up(lg) {
    max-height: 300px;
  }
}

// Signup
#signup {
  border-radius: 0;

  @include media-breakpoint-up(sm) {
    border-radius: $border-radius-lg;
  }

  &__container-start {
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 0;

    @include media-breakpoint-up(lg) {
      border-radius: $border-radius-lg 0 0 $border-radius-lg;
    }
  }
  &__container-end {
    display: flex;
    justify-content: center;
    border-radius: 0;

    @include media-breakpoint-up(lg) {
      border-radius: 0 $border-radius-lg $border-radius-lg 0;
    }
  }

  &__dots {
    position: absolute;
    right: 40px;
    bottom: 40px;
  }

  &__testimonial {
    line-height: 1.45;
  }
}

.list_item {
  display: flex;
  & p {
    margin-top: 5px;
    @include media-breakpoint-up(sm) {
      margin-top: 8px;
    }
  }
}

// Typeform
.tf__widget,
.tf-v1-widget {
  height: 80vh;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: 80vh;
    width: 80vw;
  }
}

// Login Page Separator
.separator {
  display: flex;
  align-items: center;
  color: $gray-400;
  font-size: 12px;
  text-align: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $gray-200;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
}

// OTP Validation
.form-validation-otp {
  min-height: 35px;
  font-weight: 500;
  text-align: center;
  transform: scale(1);

  @media (max-width: 375px) {
    font-size: 0.75em;
    padding: 0.75rem;
    margin: 0.25rem;
    height: 35px;
    width: 35px;
  }
  @include media-breakpoint-up(xs) {
    margin: 0.3rem;
    font-size: 1.15em;
    height: 50px;
    width: 50px;
    padding: 1rem;
  }

  @include media-breakpoint-up(sm) {
    font-size: 1.5em;
    margin: 0.35rem;
    height: 50px;
    width: 50px;
  }

  @include media-breakpoint-up(md) {
    font-size: 2em;
    margin: 0.3rem;
    height: 55px;
    width: 55px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 2em;
    margin: 0.5rem;
    height: 60px;
    width: 60px;
  }
}

// Password Change Form
#passwordHelp ul {
  padding-left: 1rem;
  margin-bottom: 0;
}

#drop-region {
  background: $gray-100;
  flex-grow: 0;
  border: 1px dashed $gray-400;
  border-radius: $border-radius;

  &.drag {
    background: $pink;
  }

  &.drag.hasItems {
    background: rgba($color: $pink, $alpha: 0.5);
  }
}

// Notifications Dropdown
.notification {
  min-width: 320px;

  &-list {
    @include media-breakpoint-up(sm) {
      position: relative;
    }

    & ul {
      position: absolute;
      width: 100%;
      max-height: 500px;
      z-index: 1000;
      background: $white;
      overflow: auto;
      right: 0;
      @include media-breakpoint-up(sm) {
        width: 500px;
      }
    }
  }

  &-item {
    border-bottom: 1px solid $gray-300;
  }

  &-item:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-up(sm) {
    min-width: 320px;
  }

  @include media-breakpoint-up(md) {
    min-width: 500px;
  }
}

.notification-title,
.notification-body {
  white-space: normal;
}
