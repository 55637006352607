@import '~bootstrap/scss/card';

.card {
  border: none;
  box-shadow: $box-shadow-sm;
  border: 1px solid $gray-100;
  border-radius: $border-radius-lg;

  &.frame-empty {
    @include frame-empty;
  }
  .card-img,
  .card-img-top {
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
  }

  .card-footer {
    background-color: transparent;
    border-top: none;
    padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x);
  }

  .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: var(--#{$prefix}card-spacer-y);
  }
}

.card-image {
  height: 220px;
  display: block;

  img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    display: block;
  }
}

.card-hover {
  box-shadow: $box-shadow-sm;
  transition: $transition-base;
  color: $body-color;
  &:hover {
    color: $body-color;
    transition: $transition-base;
    box-shadow: $box-shadow;
  }
}

.card-hover-lg {
  color: $body-color;
  transition: $transition-base;
  &:hover {
    color: $body-color;
    transform: $shiftUp;
    transition: $transition-base;
  }
}
