.videoWrapper {
  position: relative;
  padding-bottom: 70%;
  height: 0;
  overflow: hidden;
}

.videoWrapper > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoWrapper iframe {
  width: 100%;
  height: 100%;
}
