@import '~bootstrap/scss/type';

// Base
.mice {
  @extend mice;
}

.preheader {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: $font-size-sm;
}

// Decorators
// u {
//   text-decoration-color: $pink-300;
//   text-decoration-thickness: 6px;
// }

u.thick {
  text-decoration-color: $sky;
  text-decoration-thickness: 8px;
}

// RFS Bootstrap Fluid Typography
p {
  @include font-size(0.9rem);
  line-height: $line-height-md;
  @include media-breakpoint-up(sm) {
    @include font-size(0.92rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(0.94rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(0.96rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(0.98rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(1rem);
  }
}

.lead {
  @include font-size(0.95rem);
  line-height: $line-height-md;

  @include media-breakpoint-up(sm) {
    @include font-size(1.1rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(1.15rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(1.2rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(1.25rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(1.3rem);
  }
}

h1 {
  @include font-size(3.484rem);

  @include media-breakpoint-up(sm) {
    @include font-size(3.684rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(3.884rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(4.084rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(4.284rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(4.484rem);
  }
}

h2 {
  @include font-size(2.422rem);

  @include media-breakpoint-up(sm) {
    @include font-size(2.622rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(2.822rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(3.022rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(3.222rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(3.322rem);
  }
}

h3 {
  @include font-size(1.26rem);

  @include media-breakpoint-up(sm) {
    @include font-size(1.46rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(1.66rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(1.86rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(2.06rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(2.26rem);
  }
}

h4 {
  @include font-size(0.923rem);

  @include media-breakpoint-up(sm) {
    @include font-size(1.023rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(1.223rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(1.423rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(1.623rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(1.823rem);
  }
}

h5 {
  @include font-size(0.89rem);

  @include media-breakpoint-up(sm) {
    @include font-size(0.91rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(0.93rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(0.95rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(1.15rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(1.35rem);
  }
}

h6 {
  @include font-size(0.75rem);

  @include media-breakpoint-up(sm) {
    @include font-size(0.8rem);
  }
  @include media-breakpoint-up(md) {
    @include font-size(0.85rem);
  }
  @include media-breakpoint-up(lg) {
    @include font-size(0.9rem);
  }
  @include media-breakpoint-up(xl) {
    @include font-size(0.95rem);
  }
  @include media-breakpoint-up(xxl) {
    @include font-size(1rem);
  }
}
