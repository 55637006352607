@import '~bootstrap/scss/spinners';

.loadable {
  &.loading {
    background: url('~ds_img/three-dots.svg') no-repeat center center;
    min-height: 84px; // 20px more than height for padding
    * {
      display: none;
    }
  }
  &:not(.loading) {
    background-image: none;
    min-height: none;
  }
}
