@import '~bootstrap/scss/helpers';

// Custom Color maps for bg-text combined
@each $color, $value in $purples {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color} {
    color: color-contrast($value) !important;
    background-color: RGBA(
      $color-rgb,
      var(--#{$prefix}bg-opacity, 1)
    ) !important;
  }
}
@each $color, $value in $pinks {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color} {
    color: color-contrast($value) !important;
    background-color: RGBA(
      $color-rgb,
      var(--#{$prefix}bg-opacity, 1)
    ) !important;
  }
}
@each $color, $value in $yellows {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color} {
    color: color-contrast($value) !important;
    background-color: RGBA(
      $color-rgb,
      var(--#{$prefix}bg-opacity, 1)
    ) !important;
  }
}
@each $color, $value in $blues {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color} {
    color: color-contrast($value) !important;
    background-color: RGBA(
      $color-rgb,
      var(--#{$prefix}bg-opacity, 1)
    ) !important;
  }
}
@each $color, $value in $cyans {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color} {
    color: color-contrast($value) !important;
    background-color: RGBA(
      $color-rgb,
      var(--#{$prefix}bg-opacity, 1)
    ) !important;
  }
}
@each $color, $value in $grays {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color} {
    color: color-contrast($value) !important;
    background-color: RGBA(
      $color-rgb,
      var(--#{$prefix}bg-opacity, 1)
    ) !important;
  }
}
