@import '~bootstrap/scss/carousel';

.carousel-item {
  img {
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }
}

.carousel-caption {
  position: relative;
  right: (100% - $carousel-caption-width) * 0.5;
  bottom: $carousel-caption-spacer;
  left: (100% - $carousel-caption-width) * 0.5;
  padding-top: $carousel-caption-padding-y;
  padding-bottom: $carousel-caption-padding-y;
  color: $carousel-caption-color;
  text-align: center;
}

// Carousel Navigation
.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
  width: 8%;
}

.carousel-indicators__image {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 2;
  padding: 0;
  // Use the .carousel-control's width as margin so we don't overlay those
  list-style: none;

  [data-bs-target] {
    box-sizing: content-box;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    padding: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0.5rem;
    text-indent: -999px;
    cursor: pointer;
    background-color: $primary;
    background-clip: padding-box;
    border: 0;
    // Use transparent borders to increase the hit area by 10px on top and bottom.
    border-top: $carousel-indicator-hit-area-height solid transparent;
    border-bottom: $carousel-indicator-hit-area-height solid transparent;
    opacity: $carousel-indicator-opacity;
    @include transition($carousel-indicator-transition);
  }

  .active {
    opacity: $carousel-indicator-active-opacity;
  }

  @include media-breakpoint-up(lg) {
    [data-bs-target] {
      margin-bottom: 1rem;
      height: $carousel-indicator-height-lg;
      width: $carousel-indicator-width-lg;
    }
  }
}
