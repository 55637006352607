.property_list_carousel {
  position: relative;

  &--inner {
    padding-bottom: 1rem;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    scroll-behavior: smooth;
  }
  &--card {
    scroll-snap-align: start;
  }
  &--btn-right {
    position: absolute;
    right: -20px;
    bottom: 45%;
    z-index: 1050;
  }
  &--btn-left {
    position: absolute;
    left: -20px;
    bottom: 45%;
    z-index: 1050;
  }
}
