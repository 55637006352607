th {
  font-weight: 400;
}

.table-group-divider {
  border-top: none;
}

.grades {
  width: 25px;
}
.grades-overall {
  width: 50px;
}

#descriptions {
  .accordion-item:first-of-type .accordion-button {
    padding-top: 0;
  }
  button,
  .accordion-body {
    padding-left: 0;
    padding-right: 0;
  }
}
